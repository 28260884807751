import React from 'react';
import { graphql } from 'gatsby';

import Model from '../../components/configurator/model';
import Header from '../../screens/configurator/Header';

import Seo from '../../components/seo';
import { oneMatch, Product } from '../../utils';
import { trackProductList } from '../../utils/gtm';
// import ReinsuranceBanner from '../../components/eshop/ReinsuranceBanner';

import trads from '../../screens/configurator/trads';
import { useLocale } from '../../services/i18n/useLocale';
import { JASOReformatViscosity } from '../../utils/jaso';

const processTable = (table, recommendedProducts) => {
  if (!table || !recommendedProducts.length) {
    return undefined;
  }

  const currentProductId = recommendedProducts[0].shopifyId;

  const result = {
    labels: table.lines,
    columns: table.body
      .filter((col) => {
        const variants = col.primary.product?.variants.map((v) => v.sku);
        return !!recommendedProducts.find((p) => {
          return oneMatch(p.skus, variants);
        });
      })
      .map((col) => {
        const variants = col.primary.product?.variants.map((v) => v.sku);
        const product = recommendedProducts.find((p) => {
          return oneMatch(p.skus, variants);
        });
        return {
          product,
          rows: col.items.map((i) => i.content),
          current: product.shopifyId === currentProductId,
        };
      })
      .filter((item) => item.product),
  };

  // Sort columns based on current product
  const moveFrom = result.columns.findIndex(
    (col) => col.product.shopifyId === currentProductId
  );
  if (moveFrom >= 1) {
    const [element] = result.columns.splice(moveFrom, 1);
    result.columns.splice(0, 0, element);
  }
  return result;
};

const ConfiguratorModel = ({ data, pageContext }) => {
  const intl = useLocale();
  const { rootPath } = pageContext;

  const { model } = data || {};
  const { vehicle } = model;
  const { yearrange } = vehicle;

  const products = (data?.products?.nodes || []).map((p) => Product(p));
  let productPosition = 0;
  const displayedProducts = [];
  const motorOils = [];

  const sections = model.sections.map((section, idx) => ({
    ...section,
    products: section.products
      .map(({ sku, viscosity: variantName }) => {
        const product = products.find((p) => p.skus.indexOf(sku) !== -1);

        if (!product) {
          return null;
        }

        const variants = product.variants.filter((v) => {
          return v.selectedOptions.find((o) => {
            return JASOReformatViscosity(o.value) === JASOReformatViscosity(variantName);
          });
        });

        let variant = variants?.find((v) => v.availableForSale);

        if (!variant) {
          variant = variants?.[0];
        }

        if (!variant) {
          return product;
        }

        return {
          ...product,
          // urlParams: {
          //   'Viscosité': variantName
          // },
          availableForSale: variant.availableForSale,
          defaultVariant: variant,
          title: JASOReformatViscosity(`${product.title} ${variantName}`),
          productTitle: product.title,
          thumbnail: {
            ...product.thumbnail,
            originalSrc: variant.thumbnail?.originalSrc,
          },
        };
      })
      .filter((p) => p)
      .map((p) => ({
        ...p,
        trackList: 'Configurator',
        trackPosition: ++productPosition,
      }))
      .map((p) => {
        displayedProducts.push(p);

        if (idx === 0) {
          motorOils.push(p);
        }
        return p;
      })

  }));


  const table = processTable(data.table?.data, motorOils);
  console.log('sections', table, sections.length);

  if (table?.columns?.length && sections.length) {
    delete sections[0].products;
    sections[0].table = table;
  }

  trackProductList(
    displayedProducts.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.productTitle,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  const years = [yearrange.from, yearrange.to].filter(i => !!i).filter((item, pos, arr) => arr.indexOf(item) === pos).join(' - ');
  const modelTitle = `${vehicle.brand} ${vehicle.model} ${years ? `(${years})` : ''}`;

  const title = intl.formatMessage(trads.configuratorModelTitle, {
    lineBreak: '<br />',
    model: modelTitle,
  });

  return (
    <>
      <Seo
        title={intl.formatMessage(trads.configuratorModelTitle, {
          lineBreak: '',
          model: `${vehicle.brand} ${vehicle.model}`,
        })}
      />
      <Header
        title={title}
        firstLineHighlight
        darkMode
        small
        backLink={`${rootPath}/${vehicle.brandId}`}
      />
      <Model
        model={{
          ...model,
          sections,
        }}
        page={data.page?.data}
        rootPath={rootPath}
      />
      {/* <ReinsuranceBanner
        border
      /> */}
    </>
  );
};

export const query = graphql`
  query (
    $slug: String!
    $locale: String!
    $cmsLocale: String!
    $cmsLocaleGlob: String!
    $productsTableId: String
    $fetchProducts: [String!]
  ) {
    model: configuratorModelsJson(
      slug: { eq: $slug }
      locale: { eq: $cmsLocale }
    ) {
      id
      slug
      sections {
        name
        capacities
        products {
          sku
          viscosity
        }
        use {
          intervals
          type
        }
      }
      vehicle {
        brandId
        brand
        model
        yearrange {
          from
          to
        }
      }
    }
    table: prismicProductTables(id: { eq: $productsTableId }) {
      data {
        body {
          ... on PrismicProductTablesDataBodyProduit {
            id
            items {
              content {
                html
              }
            }
            primary {
              product {
                variants {
                  sku
                }
              }
            }
          }
        }
        lines {
          label
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        variants {
          availableForSale
          compareAtPrice
          image {
            originalSrc
            altText
          }
          title
          sku
          viscosity
          price
          selectedOptions {
            name
            value
          }
        }
      }
    }
    page: prismicLubadvisor(lang: { glob: $cmsLocaleGlob }) {
      data {
        body {
          ... on PrismicLubadvisorDataBodyCarousel {
            id
            slice_type
            primary {
              title
            }
            items {
              title
              link {
                url
                target
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`;

export default ConfiguratorModel;
