// @flow
import classNames from 'classnames';
import React from 'react';

// import { useWidget } from '../../components/widgets';

import trads from './trads';

import { BackButton, Wrapper } from './Header.styles';
import { useLocale } from '../../services/i18n/useLocale';
import MagicLink from '../../components/link';

const ConfiguratorHeader = ({
  title,
  firstLineHighlight = false,
  darkMode = false,
  small = false,
  backLink,
}) => {
  const intl = useLocale();
  let realTitle = title;
  // const widget = useWidget('configurator');

  if (Array.isArray(title) && firstLineHighlight) {
    realTitle = title.map((line, key) => {
      if (key === 0) {
        return (
          <span key={key} className="highlight">
            {line}
          </span>
        );
      }

      return line;
    });
  }
  return (
    <Wrapper
      className={classNames({
        dark: darkMode,
        small: small,
      })}
    >
      <div className="content">
        <div className="container">
          <h1 className="bigtitle">{realTitle}</h1>
          {backLink && (
            <BackButton>
              <MagicLink href={backLink}>{intl.formatMessage(trads.changeModel)}</MagicLink>
            </BackButton>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default ConfiguratorHeader;
