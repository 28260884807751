/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  brand: 'configurator.brand',
  brandMobile: 'configurator.brand-mobile',
  brandMobileModal: 'configurator.brand-mobile-modal',
  model: 'configurator.model',
  modelMobile: 'configurator.model-mobile',
  modelMobileModal: 'configurator.model-mobile-modal',
  year: 'configurator.year',
  yearMobile: 'configurator.year-mobile',
  yearMobileModal: 'configurator.year-mobile-modal',
  configuratorMoreTitle: 'configurator.more-title',
  contactText: 'configurator.contact-text',
  contactButton: 'configurator.contact-button',
  bestChoice: 'configurator.best-choice',
  search: 'configurator.search',
  famousBrands: 'configurator.famous-brands',
  moreProducts: 'configurator.more-products',
};
