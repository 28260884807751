import styled from 'styled-components';

import {
  device
} from '../../../utils';

export const Subtitle = styled.h2`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 15px;
  margin-top: 20px;
  text-decoration: underline;
  text-underline-offset: 5px;

  &.full-underline {
    text-decoration: none;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
  }

  @media ${device.tablet} {
    font-size: 19px;
  }
`;

export const BrandBox = styled.div`
  .change {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const OtherProductsGrid = styled.div`
  margin: 50px 0;

  ${Subtitle} {
    margin-top: 0px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;

export const BlockTitle = styled.div`
  background-color: #000;
  color: #FFF;
  padding: 20px 0;
  margin: 50px 0;

  &.red {
    background-color: #C80F05;
  }

  @media ${device.tablet} {
    margin: 70px 0;
  }

  .bigtitle {
    font-weight: bold;
  }
`;

export const AdditionnalContent = styled.div`
  section {
    margin: 20px 0 80px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  .settings {
    display: block;
    margin-bottom: 10px;

    span {
      position: relative;
      display: block;
      margin-right: 0;
      font-size: 11px;
      margin-bottom: 5px;
      padding-left: 18px;

      svg {
        height: 9px;
        position: absolute;
        left: 0;
        top: 0px;
      }
    }

    @media ${device.tablet} {
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-right: 15px;
        margin-bottom: 0px;
        padding-left: 0px;

        svg {
          position: relative;
          top: auto;
          left: auto;
          margin-right: 2px;
          height: 12px;
          width: 14px;
        }
      }
    }
  }

  .products-wrapper {
    margin-top: 10px;
    
    @media ${device.tablet} {
      margin-top: 30px;
      max-width: 305px;
    }
    /* margin-top: auto; */
  }

  /* .product-grid {
    .bestchoice {
      background-color: #c80f05;
      font-size: 11px;
      font-weight: bold;
      color: #FFF;
      text-transform: uppercase;
      padding: 5px 10px;

      @media ${device.tablet} {
        font-size: 14px;
      }
    }
  } */

  .contact-us {
    background-color: #fafafa;
    padding: 30px 30px;
    display: flex;
    flex-direction: column;
    line-height: 1.5em;

    a {
      text-decoration: none;
      border-radius: 30px;
      background-color: #c80f05;
      font-size: 15px;
      color: #FFF;
      padding: 7px 20px;
      margin-bottom: 0;
      position: relative;
      text-align: center;
      
      transition: background-color .3s;

      &:hover {
        background-color: #b30e05;
      }
    }
  }

  .bigtitle {
    text-transform: none;
  }

  .lubadvisor-title {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 15px;
    margin-top: 20px;
    text-decoration: underline;
    text-underline-offset: 5px;

    &.full-underline {
      text-decoration: none;
      padding-bottom: 5px;
      border-bottom: 1px solid #000;
    }

    @media ${device.tablet} {
      font-size: 19px;
      margin-top: 40px;
    }

  }
`;