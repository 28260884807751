// @flow

import React from 'react';

import ProductItem from '../../eshop/ProductItem';

import { Icon } from '../../icons';

import LubIcon from '../../../icons/Lub.svg';
// import ToolIcon from '../../../icons/Tool.svg';
import SlicesContainer from '../../slices';

import trads from '../trads';

import {
  Wrapper,
  Subtitle,
  Section,
  OtherProductsGrid,
  BlockTitle,
  AdditionnalContent,
} from './index.styles';
import { ProductTable } from '../../eshop';
import classNames from 'classnames';
import { useLocale } from '../../../services/i18n/useLocale';

// const renderUsage = (uses = []) => {
//   return (uses || []).map(c => (
//     <span
//       key={c.type}
//       className="use"
//     >
//       <Icon src={ToolIcon} />
//       {c.type}{c.intervals && c.intervals.length ? ':' : null} {c.intervals.join(', ')}
//     </span>
//   ));
// };

const renderCapacity = (capacities = [], needBlank = false) => {
  if (!capacities?.length && needBlank) {
    return <span className="capacity">&nbsp;</span>;
  }

  return capacities?.map((c) => (
    <span key={c} className="capacity">
      <Icon src={LubIcon} /> {c || <>&nbsp;</>}
    </span>
  ));
};

const RenderProducts = ({ section /*, showSticker*/ }) => {
  const intl = useLocale();
  const { products = [] } = section;

  if (!products?.length) {
    return (
      <div className="contact-us">
        {intl.formatMessage(trads.contactText)}

        <a href="mailto:info@ipone.fr" className="button">
          {intl.formatMessage(trads.contactButton)}
        </a>
      </div>
    );
  }

  return (
    <div className="products-wrapper">
      {(products || []).slice(0, 1).map((p, idx) => (
        <ProductItem key={idx} showStock {...p} />
      ))}
    </div>
  );
};

const SectionDispatcher = ({ section, idx }) => {
  const { capacities, table } = section;
  const intl = useLocale();

  return (
    <Section key={idx}>
      <Subtitle
        className={classNames({
          'full-underline': !table,
        })}
      >
        {section.name}
      </Subtitle>
      <div className="settings">{renderCapacity(capacities, idx !== 0)}</div>
      {/* <div className="settings">
        {renderUsage(use)}
      </div> */}
      {!!table ? (
        <ProductTable
          labels={table.labels}
          columns={table.columns}
          currentProductText={intl.formatMessage(trads.bestChoice)}
          displayFrom={false}
          showStock
        />
      ) : (
        <RenderProducts
          section={section}
        // showSticker={idx === 0}
        />
      )}
    </Section>
  );
};

const ConfiguratorModel = ({ model, page, rootPath }) => {
  const intl = useLocale();
  const { vehicle, sections } = model;
  const { yearrange } = vehicle;

  const years = [yearrange.from, yearrange.to].filter(i => !!i).filter((item, pos, arr) => arr.indexOf(item) === pos).join(' - ');
  const modelTitle = `${vehicle.brand} ${vehicle.model} ${years ? `(${years})` : ''}`;

  const title = intl.formatMessage(trads.configuratorMoreTitle, {
    lineBreak: '<br />',
    model: modelTitle,
  });

  return (
    <Wrapper>
      <div className="container">
        {/* <BrandBox>
          <div className="change">
            <Link
              className="simple-link red"
              to={`${rootPath}/${vehicle.brandId}`}
              state={{
                modelName: vehicle.model,
              }}
            >
              {intl.formatMessage(trads.changeModel)}
            </Link>
          </div>
        </BrandBox> */}

        {sections.slice(0, 1).map((section, idx) => (
          <SectionDispatcher key={idx} idx={idx} section={section} />
        ))}
      </div>
      {sections.length > 1 && (
        <>
          <BlockTitle>
            <div className="container">
              <div className="bigtitle">{title}</div>
            </div>
          </BlockTitle>
          <div className="container">
            <OtherProductsGrid className="product-grid">
              {sections.slice(1).map((section, idx) => (
                <SectionDispatcher key={idx} idx={idx} section={section} />
              ))}
            </OtherProductsGrid>
          </div>
        </>
      )}
      {page?.body?.length && (
        <AdditionnalContent>
          <BlockTitle className="red">
            <div className="container">
              <div className="bigtitle">
                {intl.formatMessage(trads.moreProducts)}
              </div>
            </div>
          </BlockTitle>
          <SlicesContainer
            content={page.body}
            titleLevel="h3"
            titleClassName="lubadvisor-title"
          />
        </AdditionnalContent>
      )}
    </Wrapper>
  );
};

export default ConfiguratorModel;
