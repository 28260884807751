import styled from 'styled-components';

import {
  device
} from '../../utils';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  &.dark {
    background: #000;
    color: #fff;
  }

  .content {
    margin: 30px 20px;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .bigtitle {
      display: block;
      margin: 0;
      font-size: 24px;
      text-align: center;

      .highlight {
        background: #c80f05;
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  @media ${device.tablet} {
    .content {
      margin-left: 0;
      margin-right: 0;
      .container {
        flex-direction: row;
      }
      .bigtitle {
        text-align: left;

        .highlight {
          margin-left: -7px;
        }
      }
    }
  }

  @media ${device.laptop} {
    .content {
      .bigtitle {
        font-size: 36px;
      }
    }
  }

  &.small {
    .content {
      margin-left: 0;
      margin-right: 0;

      .bigtitle {
        font-size: 22px;
        text-transform: none;
        line-height: 1.3em;
      }
    }

    @media ${device.laptop} {
      .content {
        .bigtitle {
          font-size: 36px;
        }
      }
    }
  }
`;

export const BackButton = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    display: inline-block;
    color: #fff;
    border: 2px solid #fff;
    padding: 8px 20px;
    border-radius: 30px;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.15);
    white-space: nowrap;
    transition: background-color 0.3s ease-in-out;
    backface-visibility: hidden;
    font-size: 14px;

  }

  @media ${device.tablet} {
    margin-top: 0;
    margin-left: auto;

    a {
      font-size: 16px;
      margin-left: 60px;
    }
  }

  @media (hover: hover) {
    a:hover {
      background-color: #000;
    }
  }
`;